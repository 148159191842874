<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :loading="loading">
      <v-container class="pa-4">
        <template>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <div>
              <div class="text-center">Add Document</div>
              <div class="d-flex justify-end ma-4">
                <div>
                  <v-select
                    :items="documentTable"
                    v-model="newDoc.documentID"
                    label="Document"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                  ></v-select>
                </div>
                <div class="mx-1">
                  <ma-date-picker
                    label="Due Date"
                    v-model="newDoc.dueDate"
                    past
                    :editable="true"
                  />
                </div>
                <div>
                  <v-btn
                    color="success"
                    :loading="loading"
                    :disabled="
                      loading ||
                      newDoc.dueDate == null ||
                      newDoc.documentID == null
                    "
                    @click="addDocument"
                  >
                    Add</v-btn
                  >
                </div>
              </div>
            </div>
          </div>

          <v-divider></v-divider>
        </template>
        <template>
          <template>
            <v-data-table
              :headers="headers"
              :loading="loadingPDocs"
              :items="patientDocuments"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.document`]="{ item }">
                {{ item.document ? item.document.name : "-" }}
              </template>
              <template v-slot:[`item.dueDate`]="{ item }">
                {{ getDAte(item.dueDate) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="item">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="primary"
                        icon
                        @click="toEdit(item)"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="error"
                        icon
                        @click="toDelete(item)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </template>
            </v-data-table>
            <v-dialog
              v-model="edit.dialog"
              persistent
              :overlay="false"
              max-width="350px"
              transition="dialog-transition"
            >
              <v-card>
                <v-toolbar color="primary" class="white--text">
                  <h3>
                    Edit Document:
                    {{ edit.item ? edit.item.document.name : "" }}
                  </h3>
                </v-toolbar>
                <v-card-text class="mt-4">
                  <template v-if="edit.item">
                    <div class="mt-2">
                      <label for="" class="font-weight-medium"
                        >Current Due Date (mm/dd/yyyy): </label
                      >{{ getDAte(edit.item.dueDate) }}
                    </div>
                  </template>

                  <v-divider class="my-2"></v-divider>
                  <div>
                    <label class="font-weight-medium"> Due Date: </label>
                    <ma-date-picker
                      v-model="edit.dueDate"
                      past
                      :editable="true"
                    />
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="resetAll">Cancel</v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="confirmEdit"
                    :loading="edit.loading"
                    :disabled="edit.loading || edit.dueDate == null"
                    >Edit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="deleteItem.dialog"
              persistent
              :overlay="false"
              max-width="350px"
              transition="dialog-transition"
            >
              <v-card>
                <v-toolbar color="error" class="white--text font-weight-bold">
                  <h3>
                    Delete Document:
                    {{ deleteItem.item ? deleteItem.item.document.name : "" }}
                  </h3>
                </v-toolbar>
                <v-card-text class="mt-4">
                  <div class="text-center body-1">Are you sure?</div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="resetAll">Cancel</v-btn>
                  <v-btn
                    color="error"
                    text
                    @click="confirmDelete"
                    :loading="deleteItem.loading"
                    :disabled="deleteItem.loading"
                    >Delete</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </template>
        <v-divider class="mb-3 mt-1" />

        <all-images
          ref="imgs"
          @uploaded="getPatient"
          @deleted="getPatient"
          :isConsent="false"
          :imagesfrom="'patient'"
          :uuid="patientDetail.uuid"
          :imagesProps="imagestoShow"
        />
        <v-divider class="my-2" />
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import AllImages from "@/components/Images/AllImages.vue";
import { mapMutations, mapState } from "vuex";
import { notifyError, notifyInfo } from "@/components/Notification";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
export default {
  name: "missing-documents",
  components: { AllImages, MaDatePicker },
  data() {
    return {
      documents: [],
      newDoc: {
        documentID: null,
        dueDate: null,
      },
      patientDocuments: [],
      loadingPDocs: false,
      edit: {
        dialog: false,
        item: null,
        loading: false,
        dueDate: null,
      },
      deleteItem: {
        dialog: false,
        item: null,
        loading: false,
      },
      currentDocument: null,
      loading: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "document",
        },
        {
          text: "Due Date",
          align: "center",
          sortable: false,
          value: "dueDate",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    documentTable() {
      if (this.documents.length == 0) {
        return [];
      }
      const d = this.documents.filter((doc) => doc.status == "ACTIVE");
      return d;
    },

    imagestoShow() {
      let img = this.patientDetail.pictures ? this.patientDetail.pictures : [];
      const consent = this.patientDetail.consentDocs
        ? this.patientDetail.consentDocs
        : [];
      img = img.concat(consent);
      console.log(img);
      return img;
    },
  },
  methods: {
    ...mapMutations("crmMedicFormModule", ["mutPatientDetails"]),
    getDAte(value) {
      if (value) {
        const y = value.split("-")[0];
        const m = value.split("-")[1];
        const d = value.split("-")[2];
        return `${m}/${d}/${y}`;
      }
      return "-";
    },
    getDocuments() {
      this.loading = true;
      getAPI(`/documents-nom/list`)
        .then((res) => {
          this.documents = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getPatientDocs() {
      const uuid = this.$route.params.uuid;
      this.loadingPDocs = true;
      getAPI(`/client-documents/list/${uuid}`)
        .then((res) => {
          this.patientDocuments = res.data;
          this.loadingPDocs = false;
        })
        .catch((error) => {
          this.loadingPDocs = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    addDocument() {
      this.loading = true;
      const uuid = this.$route.params.uuid;
      const dat = moment(this.newDoc.dueDate).toISOString();
      const body = {
        documentId: this.newDoc.documentID,
        dueDate: dat,
        patientUuid: uuid,
      };
      getAPI
        .post(`/client-documents/create`, body)
        .then((res) => {
          this.loading = false;
          this.resetAll();
          this.getPatientDocs();
          notifySuccess(`Document has been added`);
        })
        .catch((error) => {
          this.loading = false;

          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getPatient() {
      getAPI(`/patient/getPatient/${this.$route.params.uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
      });
    },

    toEdit(item) {
      this.edit = {
        loading: false,
        item,
        dialog: true,
        dueDate: null,
      };
    },
    toDelete(item) {
      this.deleteItem = {
        loading: false,
        item,
        dialog: true,
      };
    },
    confirmEdit() {
      this.edit.loading = true;
      const uuid = this.edit.item.uuid;
      const dat = moment(this.edit.dueDate).toISOString();

      getAPI
        .put(`/client-documents/update/${uuid}`, { dueDate: dat })
        .then((res) => {
          this.resetAll();
          this.getPatientDocs();
          notifyInfo(`Document has been updated`);
        })
        .catch((error) => {
          this.edit.loading = false;

          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    confirmDelete() {
      const uuid = this.deleteItem.item.uuid;
      this.deleteItem.loading = true;
      getAPI
        .delete(`/client-documents/delete/${uuid}`)
        .then((res) => {
          this.resetAll();
          this.getPatientDocs();
          notifyInfo(`Document has been deleted`);
        })
        .catch((error) => {
          this.deleteItem.loading = false;

          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    resetAll() {
      this.loading = false;
      this.newDoc = {
        documentID: null,
        dueDate: null,
      };
      this.deleteItem = {
        loading: false,
        item: null,
        dialog: false,
      };
      this.edit = {
        loading: false,
        item: null,
        dialog: false,
        dueDate: null,
      };
    },
  },
  mounted() {
    this.getDocuments();
    this.getPatientDocs();
  },
};
</script>
<style lang="scss" scoped>
.pendingclass {
  color: #ff9800;
  font-weight: 500;
}
.checkclass {
  color: #69f0ae;
  font-weight: 500;
}
</style>
