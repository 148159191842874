/* eslint-disable prettier/prettier */
export enum AllKeys {
  uuid = "Uuid",
  id = "Id",
  firstName = "First Name",
  lastName = "Last Name",
  midleName = "Middle Name",
  fullname = "Full Name",
  birthDate = "DOB",
  isMale = "Gender",
  avatar = "Avatar",
  homePhone = "Phone",
  howKnow = "Source",
  email = "Email",
  contactPreference = "Contact Preference",
  preferLanguage = "Prefer Language",
  status = "Status",
  createAt = "Create Date",
  updateAt = "Update Date",
  pictures = "Files",
  policyPlan = "Metal Plan",
  city = "City",
  state = "State",
  polizeNumber = "Policy Number",
  cardNumber = "Card Number",
  cardExpireDate = "Card Exp",
  cvv = "CVV",
  binderDay = "Binder Pay",
  monthlyPay = "Monthly Pay",
  otherPhones = "Others Phones",
  polizeStatus = "Policy Status",
  ssn = "SSN",
  legalStatus = "Legal Status",
  idNumber = "Number ID",
  expDate = "Exp Date",
  employedCompany = "Employee Company",
  workPhone = "Work Phone",
  ocupation = "Occupation",
  anualIncome = "Anual Income",
  effectiveDate = "Effective Date",
  isMaried = "Is Married ?",
  relationToPrimary = "Relation to Primary",
  address = "Address",
  mailAddress = "Mailing Address",
  billingAddress = "Billing Address",
  priceForCompany = "Price for Company",
  priceForEmployee = "Price for Employee",
  documentIds = "Documents ID",
  documents = "Documents",
  companyId = "Company",
  addressLine1 = "Address 1",
  addressLine2 = "Address 2",
  City = "City",
  State = "State",
  zipCode = "Zip Code",
}
